<template>
  <div class="markups-modal">
      <div class="markups-form-content">
        <div class="input-container">
          <div class="input-content">
            <span class="input-content__text">Min:</span>
            <input
              id="min-markup"
              type="number"
              min="0"
              oninput="this.value = Math.abs(this.value)"
              class="input-content__input"
              v-model="markups.markup_min"
            />
          </div>
          <div class="input-content">
            <span class="input-content__text">Percent:</span>
            <input
              type="number"
              min="0"
              oninput="this.value = Math.abs(this.value)"
              class="input-content__input"
              v-model="markups.markup_percent"
            />
          </div>
        </div>
        <em class="markups-form-content__info">Add the default markups</em>
        <button class="markups-button" type="button" @click="updatedAccountMarkups()">
          <span v-if="!loading">APPLY MARKUPS</span>
          <b-spinner small v-if="loading" variant="light" label="Spinning"></b-spinner>
        </button>
      </div>
  </div>
</template>

<script>
/* eslint-disable radix */
export default {
  data() {
    return {
      loading: false,
      markups: {
        markup_min: 0,
        markup_percent: 0
      }
    };
  },
  created() {
    this.markups = JSON.parse(localStorage.getItem("magaya-markups"));
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },
    async updatedAccountMarkups() {
      this.loading = true;
      await this.$store.dispatch("account/updateMagayaMarkups", {
        default_min_markup: parseInt(this.markups.markup_min),
        default_percent_markup: parseInt(this.markups.markup_percent),
      });
      this.closeModal();
      localStorage.setItem("magaya-markups", JSON.stringify(this.markups));
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.close-button {
  @include modals-close-button;
}
hr {
  margin: 0 auto 1rem auto;
}
.markups-modal {
  position: relative;
  font-family: $font-family-portal;
  &-header {
    @include modals-header;
    &__title {
      @include modals-title;
    }
  }
}
.markups-form-content {
  width: 350px;
  margin: 0px auto;
  width: 100%;
  text-align: start;
  &__label {
    margin-bottom: 0px;
  }
  .input-content {
    display: flex;
    margin-top: 5px;
    padding: 5px;
    padding-left: 10px;
    background: #ffffff;
    border: 1px solid var(--color-border-container);
    border-radius: 15px;
    font-size: 14px;
    font-weight: 500;
    &__input {
      width: 100%;
      border: none;
      outline: none;
      padding-left: 8px;
    }
    input:disabled {
      background: none;
    }
  }
  &__info {
    font-weight: 400;
    font-size: 12px;
    margin: 7px 0px;
  }
}

.markups-button {
  @include primary-button(35px);
  height: 35px;
  margin: 5px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-size: 13px;
  font-weight: 600;
  margin: none !important;
  &.disabled {
    background-color: $color-gray;
    border: none;
    color: $color-white;
    &:hover {
      background-color: $color-gray;
    }
  }
}
.input-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
</style>
