<template>
  <div class="magaya-credentials">
    <div class="magaya-credentials-form">
      <RCInput
        class="magaya-credentials-form__input"
        :class="emptyFields['username'] === true ? 'missing-field' : ''"
        label="Username"
        type="text"
        :floatLabel="true"
        :requiredField="true"
        v-model="form.username"
        @input="handleMissingStyles(form.username, 'username')"
      />
      <RCInput
        class="magaya-credentials-form__input"
        :class="emptyFields['password'] === true ? 'missing-field' : ''"
        label="Password"
        type="password"
        :floatLabel="true"
        :requiredField="true"
        v-model="form.password"
        @input="handleMissingStyles(form.password, 'password')"
      />
    </div>
    <div class="magaya-credentials-buttons">
      <button class="magaya-credentials-buttons__save" @click="closeModal()">Cancel</button>
      <button class="magaya-credentials-buttons__cancel" @click="saveButtonHandler()">Save</button>
    </div>
  </div>
</template>

<script>
import RCInput from "../../../components/RCComponents/CustomRCInput.vue";

export default {
  name: "MagayaCredentials",
  components: {
    RCInput,
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      emptyFields: {
        username: false,
        password: false,
      },
    };
  },
  methods: {
    async saveButtonHandler() {
      this.$emit("loading", true);
      if (!this.formValidation()) return;
      const credentialsValidation = await this.validateMagayaCredentials();
      const swalConfirmation = await this.swal({
        title: credentialsValidation ? "Valid credentials" : "Invalid credentials",
        text: credentialsValidation
          ? "Do you want to update your credentials? "
          : "Do you want to update your credentials anyway?",
        icon: "warning",
        buttons: ["Cancel", "Continue"],
        dangerMode: true,
      });
      if (swalConfirmation) {
        await this.updateMagayaCredentials();
      }
      this.$emit("loading", false);
      this.$emit("closeModal");
    },
    async validateMagayaCredentials() {
      return this.$store.dispatch("magaya/validateMagayaCredentials", this.form);
    },
    async updateMagayaCredentials() {
      return this.$store.dispatch("magaya/updateMagayaCredentials", this.form);
    },
    closeModal() {
      this.$emit("closeModal");
    },
    formValidation() {
      if (this.form.username && this.form.password) {
        return true;
      }
      this.emptyFields.username = !this.form.username;
      this.emptyFields.password = !this.form.password;
      return false;
    },
    handleMissingStyles(field, fieldName) {
      if (field) {
        this.emptyFields[fieldName] = false;
      } else {
        this.emptyFields[fieldName] = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/components/RCComponents.scss";

.magaya-credentials-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.magaya-credentials-buttons {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  font-family: $font-family-portal;
  &__save {
    @include primary-button;
    background-color: $color-save-button;
    padding: 7px;
    width: 47.5%;
    &:hover {
      background-color: $color-save-button;
    }
  }
  &__cancel {
    @include opposite-secondary-button;
    @include secondary-button();
    outline: 0;
    padding: 7px;
    width: 47.5%;
  }
}
</style>
